import {
  get_datiUserInfo
} from "@/api/api.utente.js";

const user = {
  namespaced: true,

  state: {
    user: '',
    role: '',
    agenteInterno: false,
    menu: [],
    idPrev: 0,
    traduzioni: [],
    lingua: '',
    obsoleto: false
  },

  mutations: {
    SET_USER: (state, user) => {
      state.user = user.Utente
      state.role = user.Tipologia
      state.menu = user.Menu
      state.agenteInterno = user.AgenteInterno
      state.traduzioni = user.Traduzioni
      state.lingua = user.Lingua
      state.obsoleto = user.Obsoleto
    },

    SET_IDPREV: (state, idPrev) => {
      state.idPrev = idPrev
    },

    SET_LINGUA: (state, lingua) => {
      state.lingua = lingua
    },
  },

  actions: {

    userInfo({
      commit,
      dispatch
    }) {

      return new Promise((resolve, reject) => {

        get_datiUserInfo().then(resp => {

          commit("SET_USER", resp.data)

          resolve(resp)

        }).catch(err => {

          reject(err)
        })
      })

    },


  },

  getters: {


    getRole: state => {

      if (state.agenteInterno)
        return "AgenteInterno"

      return state.role

    },


    getTitolo: state => link => {

      let itemFind = state.menu.find(x => x.Link === link);
      if (itemFind)
        return itemFind.Text + '|' + itemFind.Img + '|' + itemFind.Color;

      return ""

    },

    getUser: state => state.user,

    getIdPrev: state => state.idPrev,

    getTraduzioni: state => state.traduzioni,

    /*     getLingua: state => {
          return state.lingua || 'IT'   
        }, */

    traduci: state => parola => {

      let r = state.traduzioni.find(f => f.Ita.toLowerCase() == parola.toLowerCase())

      return r && state.lingua == 'FR' ? r.Fra : parola
    },

    getObsoleto: state => state.obsoleto,
  }
}


export default user
