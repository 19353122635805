var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"pa-0":"","fluid":""}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-0 mt-4",attrs:{"icon":"","color":"black"},on:{"click":_vm.caricaDati}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v("Aggiorna")])]),_c('div',{staticStyle:{"width":"130px","margin-left":"10px"}},[_c('v-select',{attrs:{"items":_vm.filtriRicerca,"hide-details":"","label":"Ordini"},model:{value:(_vm.filtroSel),callback:function ($$v) {_vm.filtroSel=$$v},expression:"filtroSel"}})],1),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{staticClass:"mt-0",attrs:{"append-icon":"search","label":_vm.$store.getters['user/traduci']('cerca'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.attesa),expression:"!attesa"}],staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.getDati,"search":_vm.search,"items-per-page":20,"options":_vm.pagination,"no-data-text":'',"no-results-text":'Nessun dato trovato',"item-key":"NumOrdine","hide-default-header":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{ pageText: '{0}-{1} di {2}' }},on:{"update:options":function($event){_vm.pagination=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('tr',{staticClass:"grey lighten-2",staticStyle:{"height":"30px","font-size":"12px"}},_vm._l((headers),function(header){return _c('th',{key:header.text,staticStyle:{"cursor":"pointer","text-align":"left"},on:{"click":function($event){return _vm.changeSort(header.value)}}},[_vm._v(" "+_vm._s(_vm.$store.getters['user/traduci'](header.text))+" ")])}),0)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticStyle:{"width":"60px","padding":"10px","text-align":"center","font-weight":"bold"}},[_vm._v(" "+_vm._s(item.NumPrev)+" ")]),_c('td',{staticStyle:{"width":"60px","padding":"5px","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$i2kFunctions.formatISODate(item.DataInvioOrdine))+" ")]),_c('td',{staticStyle:{"width":"80px","padding":"10px","text-align":"left"}},[_vm._v(" "+_vm._s(item.NumOrdine)+" ")]),_c('td',{staticStyle:{"width":"80px","padding":"10px","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$i2kFunctions.formatISODate(item.DataOrdine))+" ")]),_c('td',{staticStyle:{"width":"80px","padding":"10px","text-align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.OrdineImportato),expression:"item.OrdineImportato"}],staticClass:"ma-0",attrs:{"icon":""},on:{"click":function($event){return _vm.pdfConferma(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("picture_as_pdf")])],1)]}}],null,true)},[_c('span',[_vm._v("Apri conferma")])])],1),_c('td',{staticStyle:{"padding":"2px","text-align":"left"}},[_vm._v(" "+_vm._s(item.RagSoc)+" ")]),_c('td',{staticStyle:{"padding":"2px","text-align":"left"}},[_vm._v(" "+_vm._s(item.Riferimento)+" ")]),_c('td',{staticStyle:{"width":"100px","padding":"2px","text-align":"center","font-weight":"bold","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(item.StatoElaborazione)+" ")]),(item.Ordine)?_c('td',{staticStyle:{"padding":"2px","text-align":"center","width":"80px"}},[_c('v-icon',{attrs:{"slot":"activator","color":"red"},slot:"activator"},[_vm._v("lock")]),_c('br'),_vm._v("Ordine ")],1):_c('td',{staticStyle:{"padding":"2px","text-align":"center","width":"80px"}},[_c('v-icon',{attrs:{"slot":"activator","color":"green"},slot:"activator"},[_vm._v("lock_open")]),_c('br'),_vm._v("Preventivo ")],1),_c('td',{staticStyle:{"width":"50px","padding":"10px","text-align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.abilitaApriDocumento(item))?_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0",attrs:{"icon":""},on:{"click":function($event){return _vm.apriDoc(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("edit")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Apri")])])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }